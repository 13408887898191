import React from "react";
import { Link } from "react-router-dom";
import { PrevAndNext, Text } from "components";
import img1 from "assets/images/1.jpg";
import img2 from "assets/images/2.jpg";
import img3 from "assets/images/9.png";
import img4 from "assets/images/10.jpg";
const imagesUrl = [img1, img2, img3, img4];

const titles = ["Ахлах сургууль", "Дунд сургууль", "Бага сургууль", "Цэцэрлэг"];

// const links = [
//   "https://form.jotform.com/230327829941461",
//   "https://form.jotform.com/230271513387453",
//   "/apply/elementaryschool",
//   "/apply/kindergarten",
// ];
const links = [
  "/apply/highschool",
  "/apply/middleschool",
  "/apply/elementaryschool",
  "/apply/kindergarten",
];

function Apply() {
  return (
    <div className="px-5 md:px-14">
      <div className="container mx-auto">
        <div className="font-bold text-4xl mb-8">
          <Text>Өргөдөл илгээх</Text>
        </div>
        <p className="font-bold text-lg mb-4">
          <Text>
            Сурагчдыг зөвхөн шалгалтын оноон дээр бус бусад чухал хүчин зүйлс
            болох хувь хүний зан чанар, онцлог, хүсэл зорилго дээр үндэслэн
            элсүүлж авдаг.
          </Text>
        </p>
        <p className="mb-10">
          <Text>
            Өргөдлийн агуулга элсэх суралцах ангиас хамааран өөр өөр байна.
          </Text>
        </p>
      </div>
      <div className="grid grid-flow-row sm:grid-cols-2 gap-[10px]">
        {imagesUrl.map((url, i) => (
          <a href={links[i]} key={i} target="_blank">
            <div
              style={{
                background: `url(${url})`,
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
              }}
              className="relative pt-[75%]"
            >
              <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-40 text-3xl sm:text-4xl font-bold hover:opacity-0 transition-all duration-200">
                <Text>{titles[i]}</Text>
              </div>
            </div>
          </a>
        ))}
      </div>
      <PrevAndNext
        prevRoute={"/whoweare"}
        prevTitle="Бидний тухай"
        nextRoute={"/howweempower"}
        nextTitle="Бидний арга барил"
      />
    </div>
  );
}

export default Apply;
