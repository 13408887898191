import React from 'react'
import { PrevAndNext, Text } from 'components'
import menu from 'assets/images/menu.jpg'
import { Link } from 'react-router-dom';
import Image1 from 'assets/images/passion/chess.jpg';
import Image2 from 'assets/images/passion/football.jpg';
import Image3 from 'assets/images/passion/jiu-jitsu.jpg';
import Image4 from 'assets/images/passion/gymnastic.jpg';
import Image5 from 'assets/images/passion/violin.jpeg';
import Image6 from 'assets/images/passion/robot.jpg';

const images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
]

const titles = [
  'Шатар',
  'Хөлбөмбөг',
  'Жюү жицү',
  'Гимнастик',
  'Хийл',
  'Робот',
]

function Services() {
  return (
    <div>
      <div className='sm:container sm:mx-auto mx-6 '>
        <div className='font-bold text-4xl mb-8'><Text>Үйлчилгээ</Text>—</div>
        <Text className='mt-10 text-xl font-bold'>Хоолны цэс</Text>
        <img src={menu} alt='menu' className='max-w-[800px] w-full mt-5'/>
        <div className='mt-10'>
          <Text className='text-xl font-bold'>Passion хөтөлбөр</Text>
        </div>
        <div className='gap-10 grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-5'>
          {
            images.map((url, urlKey) => {
              return (
                <Link>
                  <div key={urlKey} style={{background: `url(${url})`, backgroundPosition:'50% 50%', backgroundSize:'cover'}} className='relative pt-[75%]'>
                    <div className='absolute inset-0 flex justify-center items-center bg-white bg-opacity-60 sm:text-4xl text-3xl font-bold hover:opacity-0 transition-all duration-200'><Text>{titles[urlKey]}</Text></div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
      <PrevAndNext prevRoute={'/facilities'} prevTitle='Бүтээн байгуулалт' nextRoute={'/community'} nextTitle='Хүчирхэг хүрээлэл'/>
    </div>
  )
}

export default Services
