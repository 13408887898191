import React from 'react'
import { PrevAndNext, Text } from 'components'
import community from 'assets/images/1.jpg'
function Community() {
  return (
    <div className='sm:container sm:mx-auto mx-6 '>
      <div className='font-bold text-4xl mb-8'><Text>Хүчирхэг хүрээлэл</Text>—</div>
      <div className='flex justify-center'>
        <img src={community} alt='community' className='w-full'/> 
      </div>
      <PrevAndNext prevRoute={'/services'} prevTitle='Үйлчилгээ' nextRoute={'/contact'} nextTitle='Холбоо барих'/>
    </div>
  )
}

export default Community