import { Text } from 'components';
import React from 'react';
import { Link } from 'react-router-dom';
import HIS from 'assets/logos/HUMAN NEW.png';
import tomujin_alt from 'assets/logos/tomujin_alternative.png';
import eco_ger from 'assets/logos/eco_ger.png';
import tomujin_tech from 'assets/logos/tomujin_tech.png';
import tomujin_academy from 'assets/logos/tomujin_academy.png';
import elf_verse from 'assets/logos/elf_verse.png';
import elf_school from 'assets/logos/Asset 50.png';
import human_urguu from 'assets/logos/HUMAN URGUU.png';

function WhoWeAre() {
  return (
    <div className='px-5 md:px-14 '>
       <div className='font-bold text-4xl mb-8'><Text>Бидний Түүх</Text>—</div>
      <div className='flex flex-col sm:flex-row justify-center gap-10 sm:gap-20 xl:gap-40'>
        {/* left branches */}
        <div className='flex-1 flex flex-col gap-10 sm:gap-24'>
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5'>
            <div className='hidden sm:block absolute -right-[calc(40px/0.7)] sm:-right-[calc(80px/0.7)] xl:-right-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 rotate-45 origin-left'>
            </div>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl '>
                <Text>Эко гэр цэцэрлэг</Text>
              </div>
              <Text >
                Канадын олон улсын Онтарио хөтөлбөрийг Монгол хүүхдүүдийн онцлогт зориулан 2015 оны 2 сараас 1-5 настай 120 хүүхдийг элсүүлэн эхэлж байжээ.
              </Text>
            </div>
            <div className='w-40 order-first lg:order-last flex justify-start lg:justify-end'>
              <img className='w-32 ' alt='eco' src={eco_ger}/>
            </div>
          </div>          
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5'>
            <div className='hidden sm:block absolute -right-[calc(40px/0.7)] sm:-right-[calc(80px/0.7)] xl:-right-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 rotate-45 origin-left'>
            </div>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl'>
                <Text>Томүжин дижитал</Text>
              </div>
              <Text>
                Томүжин академийн сургалтыг цахим хэлбэрт шилжүүлэх зорилгоор 2019 онд байгуулагдаж байжээ. ЭЛФ сургуулиудын цахим системийг хариуцан боловсролыг хүртээмжтэй болгохоор ажиллаж банйна.
              </Text>
            </div>
            <img className='w-40 order-first lg:order-last' alt='tomujin digital' src={tomujin_tech}/>
          </div>          
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5'>
            <div className='hidden sm:block absolute -right-[calc(40px/0.7)] sm:-right-[calc(80px/0.7)] xl:-right-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 rotate-45 origin-left'>
            </div>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl'>
                <Text>Томүжин Алтернатив Сургууль</Text>
              </div>
              <Text>
                Монголын анхны альтернатив хөтөлбөртэй ахлах сургууль бөгөөд төслийн арга барил болон Дэлхийн топ сургуулиудад бэлтгэх College prep хөтөлбөртэйгөөр 2020 онд 188 сурагчтайгаар үйл ажиллагаа эхлүүлж байсан.
              </Text>
            </div>
            <img className='w-40 order-first lg:order-last' alt='tomujin alternative' src={tomujin_alt}/>
          </div>         
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5'>
            <div className='hidden sm:block absolute -right-[calc(40px/0.7)] sm:-right-[calc(80px/0.7)] xl:-right-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 rotate-45 origin-left'>
            </div>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl'>
                <Text>Хүмүүн өргөө цэцэрлэг</Text>
              </div>
              <Text>
                Хүмүүн олон улсын сургуулийн дэргэдэх Хүмүүн өргөө цэцэрлэгийг 2021 онд албан ёсоор баталгаажуулж 4-5 настай хүүхдүүдийг элсүүлэн авсан.
              </Text>
            </div>
            <img className='w-40 order-first lg:order-last' alt='humuun urguu' src={human_urguu}/>
          </div>          
        </div>

          {/* tree body */}
        <div className='hidden sm:block border-black border-r-4 relative'>
          <div className='absolute w-[4px] bg-black h-[calc(160px/0.85)] -bottom-[calc(160px/0.85)]'></div>
        </div>

        {/* right branches */}
        <div className='flex-1 flex flex-col gap-10 sm:gap-24'>
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5'>
            <div className='hidden sm:block absolute -left-[calc(40px/0.7)] sm:-left-[calc(80px/0.7)] xl:-left-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 -rotate-45 origin-right'>
            </div>
            <img className='w-40' alt='tomujin' src={tomujin_academy}/>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl'>
                <Text>Томүжин Академи</Text>
              </div>
              <Text>
              2015 оны 5 сард байгуулагдаж ахлах ангийн сурагчдыг дэлхийн топ их сургуулиудад бэлтгэх College prep хөтөлбөр нь SAT болон TOEFL шалгалтын бэлтгэл эсээ бичих, төсөл хэрэгжилт, хүсэл сонирхлоо олох хичээлүүдтэй Монголын анхны их сургуулийн цогц програмыг ахлах ангийн сурагчдад нэвтрүүлсэн.
              </Text>
            </div>
          </div>
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5'>
            <div className='hidden sm:block absolute -left-[calc(40px/0.7)] sm:-left-[calc(80px/0.7)] xl:-left-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 -rotate-45 origin-right'>
            </div>
            <img className='w-40' alt='human' src={HIS}/>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl'>
                <Text>Хүмүүн олон улсын сургууль</Text>
              </div>
              <Text>
                Монголын анхны альтернатив хөтөлбөртэй бага болон дунд сургууль бөгөөд тоглоомын арга барил болон төслийн арга барилаар дамжуулан хүүхдийн төрөлхийн сониуч занг төлөвшүүлэхээр 2020 онд 138 сурагчтайгаар үйл ажиллагаагаа эхлүүлж байсан.
              </Text>
            </div>
          </div>
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5'>
            <div className='hidden sm:block absolute -left-[calc(40px/0.7)] sm:-left-[calc(80px/0.7)] xl:-left-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 -rotate-45 origin-right'>
            </div>
            <img className='w-40' alt='elf' src={elf_school}/>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl'>
                <Text>ЭЛФ эдүкейшн</Text>
              </div>
              <Text>
              Хүмүүн, Томүжин сургууль үйл ажиллагаагаа явуулж эхлэхтэй зэрэгцэн Боловсролын 4 сургууль нэгдэн Амьдралд бэлэн хүчирхэг хүнийг бий болгох зорилгоор ELF education LLC үүсгэн байгуулагдсан. Одоогоор ELF education-ны харьяа Эко гэр цэцэрлэг, Хүмүүн сургууль, Томүжин академи, Томүжин Алтернатив сургууль, ЭЛФ хөтөлбөрийн компаниуд багтаж байна.
              </Text>
            </div>
          </div>
          <div className='relative flex flex-col items-start lg:flex-row gap-5 sm:gap-10 border-black sm:border-b-2 pb-5 pb-5'>
            <div className='hidden sm:block absolute -left-[calc(40px/0.7)] sm:-left-[calc(80px/0.7)] xl:-left-[calc(160px/0.7)] -bottom-[2px] w-[calc(40px/0.7)] sm:w-[calc(80px/0.7)] xl:w-[calc(160px/0.7)] border-black border-b-2 -rotate-45 origin-right'>
            </div>
            <img className='w-40' alt='elfverse' src={elf_verse} style={{filter: "drop-shadow(0px 7px 23px #000)"}}/>
            <div className='flex-1'>
              <div className='font-bold mb-1 text-xl'>
                <Text>ЭЛФ верс</Text>
              </div>
              <Text>
                Амьдралын хэв маяг ѳѳрчилж, зан үйлд нь хүчтэй нѳлѳѳ үзүүлэхийн тулд 2021 онд ЭЛФ цар хүрээгээ тэлж ХҮЧИРХЭГ хүнийг бүтээх эко систем дэх боловсролын 12 байгууллагын НЭГДЭЛ болсон юм. Одоогоор Эко гэр цэцэрлэг, Хүмүүн олон улсын сургууль, Хүмүүн өргөө цэцэрлэг, Томүжин академи, Томүжин дижитал, Томүжин Алтернатив сургууль, ЭЛФ хөтөлбөр, ЭЛФ нютришн, ЭЛФ нэкст дор зэрэг компаниуд юм.
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-centermx-auto mt-5 sm:mt-60'>
        <div className='font-bold mb-1 text-xl'>
          <Text>Хүмүүн олон улсын сургууль нь 2022 оноос эхлэн 10-12-р ангидаа элсэлт авч эхэлсэн.</Text>
        </div>
        <Text>
          2022-2023 оны хичээлийн жилд цогцолбор сургууль болж өргөжин, сургуулийн өмнөх боловсрол 4 болон 5 настай хүүхдүүд, бага сургууль, дунд сургууль, ахлах сургууль элсэлтээ аван үйл ажиллагаа явуулах гэж байна. Мөн 9-12-р ангидаа ЭЛФ хөтөлбөр мөн дэлхийн их сургуулиудад бэлтгэх college prep хөтөлбөр болон IB хөтөлбөрөөр суралцах болно.
        </Text>
        <iframe className='mx-auto mt-10 w-full max-w-[1268px]' width="1268" height="713" src="https://www.youtube.com/embed/5r66YDYfMNI" title="Human International School - Our Campus" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div className='py-6 sm:py-14 flex justify-end text-3xl sm:text-4xl font-bold'>
        <Link to={'/apply'} className='flex items-center gap-3 sm:gap-5'><Text className='text-right'>Өргөдөл илгээх</Text> <ion-icon name="chevron-forward-outline"></ion-icon></Link>
      </div>
    </div>
  )
}

export default WhoWeAre
