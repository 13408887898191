import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN
    },
  },
  lng: 'mn',
  fallbackLng: 'mn',
  interpolation: {
    escapeValue: false
  }
});


export default i18n;
