import { Text } from 'components'
import React from 'react'
import { Link } from 'react-router-dom'
import Image1 from 'assets/images/DSC07033.jpg';
import Image2 from 'assets/images/IMG_2383.jpg';
import Image3 from 'assets/images/IMG_2135.jpg';
import Image4 from 'assets/images/IMG_2662.jpg';
import Image5 from 'assets/images/buteen-baiguulalt.png';
import Image6 from 'assets/images/6.jpg';
import Image7 from 'assets/images/7.jpg';
import Image8 from 'assets/images/8.jpg';

const imagesUrl = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
]

const titles = [
  'Бидний тухай',
  'Өргөдөл илгээх',
  'Бидний арга барил',
  'Бид хэрхэн ажилладаг вэ?',
  'Бүтээн байгуулалт',
  'Үйлчилгээ',
  'Хүчирхэг хүрээлэл',
  'Холбоо барих',
]

const routes = [
  '/whoweare',
  '/apply',
  '/howweempower',
  '/howweoperate',
  '/facilities',
  '/services',
  '/community',
  '/contact',
]

function XYZ() {


  return (
    <div className='grid grid-flow-row sm:grid-cols-2 gap-[10px] px-5 md:px-14'>
      {
        imagesUrl.map((url, i) => (
          <Link to={routes[i]}>
            <div key={i} style={{background: `url(${url})`, backgroundPosition:'50% 50%', backgroundSize:'cover'}} className='relative pt-[75%]'>
              <div className='absolute inset-0 flex justify-center items-center bg-white bg-opacity-60 sm:text-4xl text-3xl font-bold hover:opacity-0 transition-all duration-200'><Text>{titles[i]}</Text></div>
            </div>
          </Link>
        ))
      }

    </div>
  )
}

export default XYZ
