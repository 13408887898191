import React from 'react'
import { PrevAndNext, Text } from 'components'
import image from 'assets/images/2.jpg'
function HowWeEmpower() {
  return (
    <div>
      <div className='sm:container sm:mx-auto mx-6'>
        <div className='font-bold text-4xl mb-8'><Text>Бидний арга барил</Text>—</div>
        <div className='font-bold'><Text>Хүчирхэгжсэн хүн гэж хэн бэ?</Text></div>
        <div className='mb-4'>
          <Text>
            Хүчирхэг хүн гэдэг нь амьдралыг өөрийн зарчмаар амьдардаг, өөрийгөө мэддэг ба сонсдог, өөртөө итгэлтэй, ийм ч учир ямар нэгэн хэцүү үе тулгархад өөртэйгээ ажиллаж даван гарч чаддаг хүнийг хэлдэг.
          </Text>
        </div>
        <div className='flex flex-col sm:flex-row mb-5'>
          <div className='flex-1'>
            <img src={image} alt='how_we_empower' width='100%'/>
          </div>
          <ul className='sm:ml-10 mt-5 sm:mt-0 flex-1'>
            <li><Text className="font-bold">Бие болон сэтгэлийн хат: </Text><Text>ЭЛФ хүн нь хатуужилтай байж аливаа хүнд хэцүү цаг үе, саад бартааг даван туулах сэтгэлийн болон биеийн хаттай байдаг.</Text></li>
            <li><Text className="font-bold">Бие даан суралцагч: </Text><Text>ЭЛФ сурагч нь сониуч ба аливаа мэдлэг болон чадварын сурах аргачлалыг эзэмшсэн байдаг ба амьдралынхаа туршид суралцах хүсэл сонирхлоо хадгалж үлддэг.</Text></li>
            <li><Text className="font-bold">Хүсэл тэмүүлэл: </Text><Text>ЭЛФ хүн нь өөрийг нь бусдаас онцгой болгодог хүсэл сонирхолтой ба үүнийгээ уйгагүй төлөвшүүлдэг.</Text></li>
            <li><Text className="font-bold">Эрүүл бие болон саруул ухаан: </Text><Text>ЭЛФ хүн нь өөрийн бие махбод болон сэтгэл зүйн онцлогийг мэддэг. Үүний үндсэн дээр өөртэйгээ ажиллаж бие бялдар болон сэтгэл хөдлөлөө удирдаж чаддаг.</Text></li>
            <li><Text className="font-bold">Асуудлыг шийдвэрлэх: </Text><Text>ЭЛФ хүн нь аливаа асуудалтай нүүр тулгарч шийдэж чаддаг. Ингэхдээ тухайн асуудалд шүүмжлэлтэйгээр болон бүтээлчээр ханддаг.</Text></li>
            <li><Text className="font-bold">Үр дүнтэй хамтын ажиллагаа: </Text><Text>ЭЛФ хүн нь багаар ажиллах арга ухаанд гарамгай суралцсан байдаг ба бусадтай үр дүнтэй хамтарч болон харилцаж чаддаг.</Text></li>
          </ul>
        </div>

        <div className='flex flex-col gap-4'>
          <div>
            <div className='font-bold'><Text>Бидний хөтөлбөр</Text></div>
            <Text>
              Хүмүүн олон улсын сургууль нь ЭЛФ сургалтын хөтөлбөр болон International Baccalaureate буюу IB хөтөлбөрийн уялдаатайгаар хамтатган хүүхдүүдээ Хүчирхэгжүүлдэг.
            </Text>
          </div>
          <div>
            <div className='font-bold'><Text>ЭЛФ хөтөлбөр</Text></div>
            <Text>
              ЭЛФ хөтөлбөр нь асар хурдацтай өөрчлөгдөж буй амьдралд бэлэн хүчирхэгжсэн хүнийг бий болгох, тэдэнд цаглашгүй чадваруудыг эзэмшүүлэх зорилготой.
            </Text>
          </div>
          <div>
            <div className='font-bold'><Text>ЭЛФ хөтөлбөрийн ЦОГЦ аргачлал</Text></div>
            <ul>
              <li><Text>Life tools:</Text> <Text>Дэлхийн түвшний академик мэдлэгийг хэрэглээтэй уялдуулан суралцдаг</Text></li>
              <li><Text>Life skills:</Text> <Text>Ямар ч цаг үед хуучиршгүй ур чадваруудыг эзэмшдэг</Text></li>
              <li><Text>Life experience:</Text> <Text>Туршлага хуримтлуулж, өргөн цар хүрээтэй хөгждөг</Text></li>
              <li><Text>Life style:</Text> <Text>Эрүүл, зөв, өсөн дэвжих амьдралын хэв маягыг суулгадаг</Text></li>
            </ul>
          </div>
          <div><Text className='font-bold'>College prep:</Text> <Text>Гадаад, дотоодын топ их дээд сургуульд бэлтгэх College prep хөтөлбөрт 9-р ангиас бэлтгэнэ.Томүжин академийн 6 жилийн туршлага</Text></div>
          <div>
            <div className='font-bold'><Text>Олон улсны бакалаврын хөтөлбөр</Text></div>
            <Text>
              Олон улсад хүлээн зөвшөөрөгдсөн  IB хөтөлбөрөөр дэлхийн түвшний академик мэдлэгийг эзэмшинэ. Ахлах ангийн сурагч нарын хувьд IB хөтөлбөр, ЭЛФ хөтөлбөр college prep хөтөлбөрийн сонгон суралцан төгсөх боломжтой.
            </Text>
          </div>
          <div className='font-bold'>The IB Primary Years Program (PYP),</div>
          <div className='font-bold'>The IB Middle Years Program (MYP), </div>
          <div className='font-bold'>The IB Diploma Program (DP),</div>
          <div>
            <div className='font-bold'><Text>Төсөл дээр суурилсан сурах арга зүй</Text></div>
            <Text>
              Project Based Learning буюу төсөлд суурилсан сурах аргачлал нь бодит амьдрал дээр тохиолдож буй нөхцөл байдал, асуудал, үйл явдалд үндэслэж тэдгээрт дүн шинжилгээ хийх, дүгнэлт гаргах, шийдэл олох замаар суралцах нэгэн төрлийн идэвхитэй суралцах арга барил юм. Хүмүүн сургуулийн сурагчид эдгээр төсүүдийг хэрэгжүүлэхдээ асуудлыг тодорхойлох, судалгаа хийх, асуудлын шийдлийг гаргах замаар шүүмжлэлт сэтгэлгээ, багаар үр дүнтэй ажиллах, шийдвэр гаргах, хөрвөх гэх 21-р зуунд амжилттай амьдрахад шаардагдах мэдлэг болон чадваруудад суралцдаг.
            </Text>
          </div>
          <div>
            <div className='font-bold'><Text>Хаус систем</Text></div>
            <Text>
              ЭЛФ Сургуулийн сурагчид анги бус Хаусаар зохион байгуулагддаг. Уламжлалт сургуулиудын анги гэх ойлголтоос ялгаатай нь Хауст нэг насны, адилхан амьдралын туршлагатай хүүхдүүд байдаггүй. Хауст цэцэрлэгээс 12-р ангийн төрөл бүрийн хүсэл сонирхол, зан араншин, туршлагатай хүүхдүүд хуваарьлагдан орсноор ах эгчтэй байх дүүтэй байх мэдрэмж нь илүү гэр бүлийн уур амьсгалтай нэгнээ хайрлах, туслах, дэмжих зорилгыг агуулсан байдаг.
            </Text>
          </div>
          <div>
            <div className='font-bold'><Text>Passion program</Text></div>
            <Text>
              Passion programme нь хүүхэд бүрийн хүсэл сонирхол дэр суурьлан сурагчдын хөгжүүлэхийг хүссэн дугуйлан, секц, програмыг нэвтрүүлдэг.
            </Text>
          </div>
        </div>
      </div>
      <PrevAndNext prevRoute={'/apply'} prevTitle='Өргөдөл илгээх' nextRoute={'/howweoperate'} nextTitle='Бид хэрхэн ажилладаг вэ?'/>
    </div>
  )
}

export default HowWeEmpower