import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from './footer'
import Header from './header'
import EventBanner from 'pages/eventBanner'

function Layout() {
  const location = useLocation()

  useEffect(() => {
    document.documentElement.scrollTo(0,0)
  }, [location])

  return (
    <div>
      <Header/>
      <Outlet></Outlet>
      <Footer/>
    </div>
  )
}

export default Layout
