import React from 'react';

function FormPage() {
  return (
    <div>
      <iframe
        src="https://share.hsforms.com/1IDEAIao4SBurY7M8jBOELQob0en"
        title=""
        width="full" // Set the width as desired
        height="400" // Set the height as desired
        frameBorder="0" // Set to "0" to remove the iframe border
        allowFullScreen // Optional: Allow the iframe to go fullscreen
      />
      <div>
      <iframe src="https://share.hsforms.com/1IDEAIao4SBurY7M8jBOELQob0en"></iframe>

      </div>
    </div>
    
  );
}

export default FormPage;
