import { Text } from 'components'
import React from 'react'
import { useParams } from 'react-router-dom'
import fall1 from 'assets/images/fall-1.png'
import fall2 from 'assets/images/fall-2.png'
import fall3 from 'assets/images/fall-3.png'
import winter1 from 'assets/images/winter-1.png'
import winter2 from 'assets/images/winter-2.png'
import winter3 from 'assets/images/winter-3.png'
import winter4 from 'assets/images/winter-4.png'
import spring1 from 'assets/images/spring-1.png'
import spring2 from 'assets/images/spring-2.png'
import spring3 from 'assets/images/spring-3.png'
import spring4 from 'assets/images/spring-4.png'

function SchoolType() {
  const { id } = useParams()
  return (
    <div className='sm:container sm:mx-auto mx-6'>
      {
        id === 'highandmiddle' ?
        <>
          <div className='font-bold text-4xl mb-8'><Text>Ахлах болон Дунд сургууль</Text>—</div>
          <div className='font-bold text-2xl mb-2'>2022-2023 оны хичээлийн жилийн сургалтын бүтэц зохион байгуулалт</div>
          <ul className='list-disc ml-8'>
            <li><span>Намрын улирал:</span> 8.22-11.18 нийт 13 долоо хоног</li>
            <li><span>Өвлийн улирал:</span> 11 сарын 28 - 2023 оны 3 сарын 3, дундаа 3 долоо хоног шинэ жил, хичээл 11 долоо хоног</li>
            <li><span>Хаврын улирал:</span> 3 сарын 13 - 6 сарын 9 нийт 13 долоо хоног</li>
          </ul>
          <div className='mt-5'>9-12 анги</div>
          <table>
            <thead className=' bg-green-700 text-white'>
              <tr>
                <td>Start</td>
                <td>End</td>
                <td>Monday</td>
                <td>Tuesday</td>
                <td>Wednesday</td>
                <td>Thursday</td>
                <td>Friday</td>
              </tr>
            </thead>
            <tbody>
              <tr className='font-bold'>
                <td className='w-[70px]'>8:30</td>
                <td className='w-[70px]'>9:10</td>
                <td className='w-[170px]'>Opening ceremony</td>
                <td className='w-[170px]'>Assembly</td>
                <td className='w-[170px]'>Assembly</td>
                <td className='w-[170px]'>Assembly</td>
                <td className='w-[170px]'>Assembly</td>
              </tr>
              <tr className='font-bold'>
                <td>9:40</td>
                <td>10:00</td>
                <td>Breakfast</td>
                <td>Breakfast</td>
                <td>Breakfast</td>
                <td>Breakfast</td>
                <td>Breakfast</td>
              </tr>
              <tr className='font-bold'>
                <td>10:00</td>
                <td>10:40</td>
                <td>ENGLISH</td>
                <td>MONGOLIA</td>
                <td>CHINESE</td>
                <td>ENGLISH</td>
                <td>MONGOLIA</td>
              </tr>
              <tr className='text-gray-500'>
                <td className='font-bold text-black'>10:40</td>
                <td className='font-bold text-black'>10:45</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
              </tr>
              <tr className='font-bold'>
                <td>10:45</td>
                <td>11:25</td>
                <td>MATHEMATICS</td>
                <td>MATHEMATICS</td>
                <td>MATHEMATICS</td>
                <td>SOCIAL</td>
                <td>MATHEMATICS</td>
              </tr>
              <tr className='text-gray-500'>
                <td className='font-bold text-black'>11:25</td>
                <td className='font-bold text-black'>11:30</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
              </tr>
              <tr className='font-bold'>
                <td>11:30</td>
                <td>12:10</td>
                <td>PHYSICS</td>
                <td>CHEMISTRY</td>
                <td>ENGLISH</td>
                <td>CHEMISTRY</td>
                <td>LIFE STYLE</td>
              </tr>
              <tr className='text-gray-500'>
                <td className='font-bold text-black'>12:10</td>
                <td className='font-bold text-black'>12:15</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
                <td>Break</td>
              </tr>
              <tr className='font-bold'>
                <td>12:15</td>
                <td>12:55</td>
                <td>CHINESE</td>
                <td>BIOLOGY</td>
                <td>PHYSICS</td>
                <td>HISTORY</td>
                <td>BIOLOGY</td>
              </tr>
              <tr className=' bg-rose-300'>
                <td className='font-bold'>12:55</td>
                <td className='font-bold'>13:35</td>
                <td>Lunch</td>
                <td>Lunch</td>
                <td>Lunch</td>
                <td>Lunch</td>
                <td>Lunch</td>
              </tr>
              <tr className='font-bold'>
                <td>13:35</td>
                <td>14:15</td>
                <td>DESIGN</td>
                <td>ENGLISH</td>
                <td>ARTS</td>
                <td>MONGOLIAN</td>
                <td>ENGLISH</td>
              </tr>
              <tr className='font-bold'>
                <td>14:15</td>
                <td>14:55</td>
                <td>ELF CULTURE</td>
                <td>LIFE SKILLS</td>
                <td>IB 9-10</td>
                <td>PBL</td>
                <td>GEOGRAPHY</td>
              </tr>
              <tr>
                <td className='font-bold'>14:55</td>
                <td className='font-bold'>15:35</td>
                <td>Passion program info</td>
                <td>Passion program info</td>
                <td>Passion program info</td>
                <td>Passion program info</td>
                <td>Passion program info</td>
              </tr>
            </tbody>
          </table>
          <div className='mt-16 text-2xl font-bold'>Fall term | Important Dates</div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={fall1} width={350} alt='fall1'/>
            <div className='font-bold sm:mt-3'>
              <div>Start: Monday, August 22 - End: Friday, November 18, 2022</div>
              <div>Orientation: Aug 22 - Sept 2</div>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={fall2} width={350} alt='fall2'/>
            <div className='font-bold sm:mt-3'>
              <Text>Orientation Student Profile: Sep. 2</Text>
              <div className='mt-2'>
                <Text>Parent's Event, Sept. 5, 6, 8, 9</Text>
                <ul className='list-disc ml-10 mt-2 font-normal'>
                  <li><Text>6th parents meeting, Sep.5</Text></li>
                  <li><Text>7th parents meeting, Sep.6</Text></li>
                  <li><Text>8th parents meeting, Sep.8</Text></li>
                  <li><Text>9th parens meeting, Sep.9</Text></li>
                </ul>
              </div>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={fall3} width={350} alt='fall3'/>
            <div className='sm:mt-3'>
              <Text className='font-bold'>Studio 1: Sept. 27 - Nov. 03</Text>
              <ul className='list-disc ml-10 mt-2 font-normal' >
                <li><Text>Pre pitch: Oct. 06</Text></li>
                <li><Text>Final pitch: Oct. 12</Text></li>
                <li><Text>Report: Nov. 03</Text></li>
              </ul>
            </div>
          </div>
          {/* Winter */}
          <div className='mt-16 text-2xl font-bold'>Winter term | Important Dates</div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={winter1} width={350} alt='winter1'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li>
                  <Text className="font-medium">Parent's Event, Nov. 22, 23</Text>
                </li>
                <li>
                  <Text>Term final exam week: &nbsp;</Text><Text className="font-medium">Nov. 14 - Nov. 18</Text>
                </li>
                <li>
                  <Text>End of Term Final Student Profile: &nbsp;</Text><Text className="font-medium"> Nov. 21</Text>
                </li>
                <li>
                  <Text className="font-medium">School Holiday, Nov. 21 - 25 </Text>
                </li>
                <li>
                  <Text>Studio Student Profile: &nbsp;</Text><Text className="font-medium"> Nov. 07	</Text>
                </li>
              </ul>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={winter2} width={350} alt='winter2'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li><Text>Studio 2: Dec.05 - Jan.26</Text></li>
                <ul className="list-disc ml-10 my-2 font-medium">
                  <li><Text>Pre pitch: Dec. 15 </Text></li>
                  <li><Text>Final pitch: Dec. 21</Text></li>
                  <li><Text>Report: Jan.26</Text></li>
                </ul>
                <li><Text>Studio Student Profile: &nbsp;</Text><Text className="font-medium">Dec. 26</Text></li>
                <li><Text>New Year Holiday, Dec. 26 - Jan. 6</Text></li>
                <li><Text>Students new year party:&nbsp;</Text><Text className="font-medium"> Dec. 24</Text></li>
              </ul>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={winter3} width={350} alt='winter3'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li><Text>Internal competition of Jiu Jitsu:&nbsp;</Text><Text className="font-medium"> Jan. 13</Text></li>
                <li><Text>Studio Student Profile:&nbsp;</Text><Text className="font-medium">  Feb. 17</Text></li>
              </ul>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={winter4} width={350} alt='winter4'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li><Text>Studio Student Profile: &nbsp;</Text><Text className="font-medium"> Feb. 20</Text></li>
                <li><Text>Exam week: &nbsp;</Text><Text className="font-medium">  Feb. 27 - Mar. 3</Text></li>
                <li><Text>Lunar Year Holiday: Feb. 20 - 24</Text></li>
              </ul>
            </div>
          </div>
          {/* SPRING */}
          <div className='mt-16 text-2xl font-bold'>Spring term | Important Dates</div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={spring1} width={350} alt='spring1'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li><Text>Start: &nbsp;</Text><Text className="font-medium"> Monday, March 13, 2023</Text> <Text>End:</Text><Text className="font-medium">Friday, June 9, 2023</Text></li>
                <li><Text>End of Term Final Student Profile: &nbsp;</Text><Text className="font-medium">  Mar. 6	</Text></li>
                <li><Text>School Holiday, Mar. 6 - 10</Text></li>
                <li><Text>Parent's Event</Text></li>
                <ul className="list-disc ml-10 my-2 font-normal">
                  <li><Text>Mar. 7 - 6, 7th</Text></li>
                  <li><Text>Mar. 9 - 8, 9 th</Text></li>
                </ul>
                <li>Studio 4: Mar. 20 - Apr. 28</li>
                <ul className="list-disc ml-10 my-2 font-normal">
                  <li><Text>Pre pitch: Mar. 31</Text></li>
                  <li><Text>Final pitch: Apr. 14</Text></li>
                  <li><Text>Report: Apr. 27</Text></li>
                </ul>
              </ul>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={spring2} width={350} alt='spring2'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li><Text>Studio 4 Exam week:  &nbsp;</Text><Text className="font-medium"> Apr. 28</Text></li>
              </ul>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={spring3} width={350} alt='spring3'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li><Text>Studio Student Profile: &nbsp;</Text><Text className="font-medium"> May.1 </Text></li>
                <li><Text>End of Term Final Exhibit:  &nbsp;</Text><Text className="font-medium"> May. 1 - 5	 </Text></li>
                <li><Text>End of Term Final Student Profile:  &nbsp;</Text><Text className="font-medium"> May. 31 </Text></li>
              </ul>
            </div>
          </div>
          <div className='mt-6 flex flex-col sm:flex-row gap-20'>
            <img src={spring4} width={350} alt='spring4'/>
            <div className='font-bold sm:mt-3'>
              <ul>
                <li><Text>Year final exam week:  &nbsp;</Text><Text className="font-medium"> Jun. 1 - 8 </Text></li>
                <li><Text>House day:   &nbsp;</Text><Text className="font-medium">Jun. 09	 </Text></li>
                <li><Text>End of Year Final Student Profile:   &nbsp;</Text><Text className="font-medium"> Jun. 12 </Text></li>
                <li><Text>School Holiday, Jun. 12 </Text></li>
                <li><Text>Parent's Event, Jun. 13 - 15 </Text></li>
              </ul>
            </div>
          </div>
        </>
        :
        <>
          <div className='font-bold text-4xl mb-8'><Text>Бага сургууль болон Цэцэрлэг</Text>—</div>
         <div className='font-bold text-2xl mb-2'>2022-2023 оны хичээлийн жилийн сургалтын бүтэц зохион байгуулалт</div>
          <ul className='list-disc ml-8'>
            <li><Text>Намрын улирал:</Text> 8 сарын 22 - 11 сарын 18, нийт 13 долоо хоног</li>
            <li><Text>Өвлийн улирал:</Text> 11 сарын 28 - 2023 оны 3 сарын 3, дундаа 3 долоо хоног шинэ жил, хичээл 11 долоо хоног</li>
            <li><Text>Хаврын улирал:</Text> 3 сарын 13 - 6 сарын 9, нийт 13 долоо хоног</li>
          </ul>
        </>

      }
    </div>
  )
}

export default SchoolType
