import React from 'react'
import { PrevAndNext, Text } from 'components'
function Facilities() {
  return (
    <div>
      <div className='sm:container sm:mx-auto mx-6 '>
        <div className='font-bold text-4xl mb-8'><Text>Бүтээн байгуулалт</Text>—</div>
        <div className='flex flex-col justify-center'>
          <iframe className='w-full max-w-[1268px]' width="1268" height="713" src="https://www.youtube.com/embed/5r66YDYfMNI" title="Human International School - Our Campus" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <PrevAndNext prevRoute={'/howweoperate'} prevTitle='Бид хэрхэн ажилладаг вэ?' nextRoute={'/services'} nextTitle='Үйлчилгээ'/>
    </div>
  )
}

export default Facilities