import React from "react";
import { Link } from "react-router-dom";
import { PrevAndNext, Text } from "components";
import img1 from "assets/images/3.jpg";
import img2 from "assets/images/11.jpg";

const imagesUrl = [img1, img2];

const titles = ["Ахлах болон Дунд сургууль", "Бага сургууль болон Цэцэрлэг"];

const routes = [
  "/howweoperate/highandmiddle",
  "/howweoperate/elementaryandkindergarden",
];

function HowWeOperate() {
  return (
    <div>
      <div className="sm:container sm:mx-auto mx-6">
        <div className="font-bold text-4xl mb-8">
          <Text>Бид хэрхэн ажилладаг вэ?</Text>—
        </div>
        <div className="font-bold sm:text-3xl text-3xl text-center mb-5">
          2022-2023 оны хичээлийн жилийн сургалтын бүтэц зохион байгуулалт
        </div>
        <div className="grid grid-cols-12 sm:gap-10 gap-5 mb-10">
          {imagesUrl.map((url, i) => (
            <Link to={routes[i]} className="sm:col-span-6 col-span-12">
              <div
                key={i}
                style={{
                  background: `url(${url})`,
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                }}
                className="relative pt-[75%]"
              >
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-40 text-3xl sm:text-4xl font-bold hover:opacity-0 transition-all duration-200">
                  {titles[i]}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="font-bold text-2xl mb-3">
          <Text>Сургалтын Төлбөр</Text>
        </div>
        <ul>
          <li>
            <Text className="font-bold">Сургуулийн өмнөх бэлтгэл:</Text>{" "}
            ₮15,000,000 (Зөвхөн 4,5 нас)
          </li>
          <li>
            <Text className="font-bold">Бага анги:</Text> ₮21,900,000 (1-5-р
            анги)
          </li>
          <li>
            <Text className="font-bold">Дунд анги:</Text> ₮25,900,000 (6-8-р
            анги)
          </li>
          <li>
            <Text className="font-bold">Ахлах анги:</Text> ₮29,900,000 (9-12-р
            анги)
          </li>
        </ul>
        <div className="font-bold text-4xl text-center mb-5 mt-8">
          Санхүүгийн Дэмжлэг
        </div>
        <div>
          Хүүхдийн сурах боломж ар гэрийн санхүүгийн бололцоогоос болж
          хязгаарлагдах ёсгүй. Бид ДЭЛХИЙН 1% Л АВЧ ЧАДДАГ ЭЛИТ БОЛОВСРОЛЫГ ХҮН
          БҮРТ ХҮРТЭЭМЖТЭЙ БОЛГОХ-ын тулд санхүүгийн дэмжлэг олгодог. Сурах
          хүсэл эрмэлзэлтэй, хүрээлэлдээ хэрэгтэй үнэ цэн бүтээж чадах хэдий ч,
          ар гэрийн санхүүгийн байдал нь сурагчийн суралцах боломжид нөлөөлж
          байвал санхүүгийн дэмжлэг олгогдоно. Санхүүгийн дэмжлэг гэдэг нь ар
          гэрийн санхүүгийн чадамжаас хамааран сурагчдын сургалтын төлбөрөөс
          суутгагдаж буй мөнгөн дүн юм. Энэ нь сургалтын нийт төлбөрийн 0-100%
          байх боломжтой бөгөөд сурагч бүрийн тохиолдолд өөр байдаг.
        </div>
      </div>
      <PrevAndNext
        prevRoute={"/howweempower"}
        prevTitle="Бидний арга барил"
        nextRoute={"/facilities"}
        nextTitle="Бүтээн байгуулалт"
      />
    </div>
  );
}

export default HowWeOperate;
