// import { Text } from 'components'
// import React from 'react'

// function Footer() {
//   return (
//     <div className='sm:container sm:mx-auto mx-6 sm:py-16 py-6'>
//       <ul className=' flex flex-col gap-4'>
//         <li>
//           <Text className='font-bold'>Бие болон сэтгэлийн хат: </Text>
//           <Text className='italic'>ЭЛФ хүн нь хатуужилтай байж аливаа хүнд хэцүү цаг үе, саад бартааг даван туулах сэтгэлийн болон биеийн хаттай байдаг.</Text>
//         </li>
//         <li>
//           <Text className='font-bold'>Бие даан суралцагч: </Text>
//           <Text className='italic'>ЭЛФ сурагч нь сониуч ба аливаа мэдлэг болон чадварын сурах аргачлалыг эзэмшсэн байдаг ба амьдралынхаа туршид суралцах хүсэл сонирхлоо хадгалж үлддэг.</Text>											
//         </li>
//         <li>
//           <Text className='font-bold'>Хүсэл тэмүүлэл: </Text>
//           <Text className='italic'>ЭЛФ хүн нь өөрийг нь бусдаас онцгой болгодог хүсэл сонирхолтой ба үүнийгээ уйгагүй төлөвшүүлдэг.</Text>
//         </li>
//         <li>
//           <Text className='font-bold'>Эрүүл бие болон саруул ухаан: </Text>
//           <Text className='italic'>ЭЛФ хүн нь өөрийн бие махбод болон сэтгэл зүйн онцлогийг мэддэг. Үүний үндсэн дээр өөртэйгээ ажиллаж бие бялдар болон сэтгэл хөдлөлөө удирдаж чаддаг.</Text>
//         </li>
//         <li>
//           <Text className='font-bold'>Асуудлыг шийдвэрлэх (critical & creative thinking): </Text>
//           <Text className='italic'>ЭЛФ хүн нь аливаа асуудалтай нүүр тулгарч шийдэж чаддаг. Ингэхдээ тухайн асуудалд шүүмжлэлтэйгээр болон бүтээлчээр ханддаг.</Text>
//         </li>
//         <li>
//           <Text className='font-bold'>Үр дүнтэй хамтын ажиллагаа: </Text>
//           <Text className='italic'>ЭЛФ хүн нь багаар ажиллах арга ухаанд гарамгай суралцсан байдаг ба бусадтай үр дүнтэй хамтарч болон харилцаж чаддаг.</Text>
//         </li>
//       </ul>
//     </div>
//   )
// }

// export default Footer

// Footer.js
// Footer.js
import React from 'react';
import logo from 'assets/logos/LogoHuman.png';
import { Text } from 'components';

function Footer() {
  return (
    <div className='p-6 flex flex-col items-center'>
      <div className='flex items-center gap-5'>
      <Text >Powered by:</Text>
      <Text style={{ fontWeight: 'bold' }}>Wisdom</Text>
        <img src={logo} alt='logo' width={50} />
        <Text style={{ fontWeight: 'bold' }}>Action</Text>
      </div>
     
    </div>
  );
}

export default Footer;

