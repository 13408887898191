import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BsYoutube } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebookSquare } from 'react-icons/fa';
import logo from 'assets/logos/LogoHuman.png';
import { Text } from 'components';

function Header() {
  const { i18n } = useTranslation();

  const handleChange = (e) => {
    e.stopPropagation();
    i18n.changeLanguage(i18n.language === 'en' ? 'mn' : 'en');
  };

  return (
    <div className='px-5 sm:px-14 py-16 flex justify-between items-center h-[40px] md:h-[100px] relative'>
      <Link to={'/xyz'}>
        <Text className='text-primary text-lg font-bold'>Бидний арга барил</Text>
      </Link>
      <div
        className='absolute flex justify-center items-center'
        style={{
          top: '0',
          left: '50%',
          bottom: '0',
          transform: 'translate(-50%, 0)',
        }}
      >
        <Link to={'/'} className='flex justify-center items-center relative z-20 gap-5'>
          <Text style={{ fontWeight: 'bold', fontSize: '1rem', sm: '1.5rem' }} className="hidden sm">Wisdom</Text>
          <img src={logo} alt='logo' width={50} />
          <Text style={{ fontWeight: 'bold', fontSize: '1rem', sm: '1.5rem' }} className="hidden sm">Action</Text>
        </Link>
      </div>
      <div className='flex items-center gap-5'>
        <div className='hidden sm:flex items-center'>
          <a className='p-2' href='https://www.youtube.com/@humaninternationalschool6949' target='_blank' rel='noreferrer'>
            <BsYoutube className='transition-all text-xl text-primary hover:text-primary/80'></BsYoutube>
          </a>
          <a className='p-2' href='https://www.instagram.com/humanintlschool/' target='_blank' rel='noreferrer'>
            <AiFillInstagram className='transition-all text-lg text-primary hover:text-primary/80'></AiFillInstagram>
          </a>
          <a className='p-2' href='https://www.facebook.com/humaninternationalschool' target='_blank' rel='noreferrer'>
            <FaFacebookSquare className='transition-all text-lg text-primary hover:text-primary/80'></FaFacebookSquare>
          </a>
        </div>
        <button onClick={handleChange}>
          <span className={`transition-all ${i18n.language === 'en' && 'font-bold text-lg text-primary'}`}>
            en
          </span>{' '}
          /{' '}
          <span className={`transition-all ${i18n.language === 'mn' && 'font-bold text-lg text-primary'}`}>
            mn
          </span>
        </button>
      </div>
    </div>
  );
}

export default Header;
