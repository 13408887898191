import React from 'react'
import { useTranslation } from 'react-i18next'

function Text(props) {
  const { t } = useTranslation()
  return (
    <span {...props}>{t(props.children)}</span>
  )
}

export default Text