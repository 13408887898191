import React from 'react'
import { Link } from 'react-router-dom'
import Text from './Text'

function PrevAndNext({nextRoute, prevRoute, nextTitle, prevTitle}) {
  return (
    <div className='py-6 sm:py-14 px-6 flex justify-between text-3xl sm:text-4xl font-bold'>
      { prevRoute &&
        <Link to={prevRoute} className='flex items-center gap-3 sm:gap-5'><ion-icon name="chevron-back-outline"></ion-icon> <Text>{prevTitle}</Text></Link>
      }
      {
        nextRoute &&
        <Link to={nextRoute} className='flex items-center gap-3 sm:gap-5'><Text className='text-right'>{nextTitle}</Text> <ion-icon name="chevron-forward-outline"></ion-icon></Link>
      }
    </div>
  )
}

export default PrevAndNext