import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout";
import {
  Apply,
  ApplySchools,
  Community,
  Contact,
  CoverPage,
  Facilities,
  Services,
  WhoWeAre,
  HowWeEmpower,
  HowWeOperate,
  XYZ,
  SchoolType,
  QPayPage,
  QpayPage,
} from "./pages";
import "./i18n";
import GoogleAnalytics from "components/GoogleAnalytics";

function App() {
  return (
    <Suspense>
      <BrowserRouter>
        {/* <GoogleAnalytics /> */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<CoverPage />} />
            <Route path="/apply" element={<Apply />} />
            <Route path="/apply/:id" element={<ApplySchools />} />
            <Route path="/community" element={<Community />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/facilities" element={<Facilities />} />
            <Route path="/services" element={<Services />} />
            <Route path="/whoweare" element={<WhoWeAre />} />
            <Route path="/howweempower" element={<HowWeEmpower />} />
            <Route path="/howweoperate" element={<HowWeOperate />} />
            <Route path="/howweoperate/:id" element={<SchoolType />} />
            <Route path="/xyz" element={<XYZ />} />
            <Route path="/qpay" element={<QpayPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
