import React from 'react'
import { Link } from 'react-router-dom';
import { Text } from '../../components';
import img from 'assets/images/IMG_2633.jpg';


function CoverPage() {
  return (
    <div className='h-screen' >
      <div className="relative h-full">
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-none" style={{zIndex: -1}}>
        <img
          src={img}
          alt='cover_img'
          height={'1005.75px'}
          width='100%'
          className='absolute w-full h-full object-cover object-center'
          // style={{ opacity: 1 }}
        />
        <div className='absolute w-full h-full bg-black opacity-40'></div>
          </div>
          <div className="z-50 w-full pt-[10vw] px-[3vw] ">
            <p className="font-bold text-5xl text-white mb-10 lg:w-1/2 ">
              <Text style={{textShadow: '0px 0px 6px rgba(0,0,0,0.51)'}}>
                ХҮҮХЭД БҮРИЙН ӨӨРИЙНХ НЬ ХАМГИЙН ШИЛДЭГ ХУВИЛБАРЫГ БҮТЭЭНЭ
              </Text>
            </p>
            <p className="font-bold text-lg text-white mb-10 lg:w-1/2">
              <Text style={{textShadow: '0px 0px 6px rgba(0,0,0,0.51)'}}>
                Хүмүүн олон улсын сургууль нь сургуулийн өмнөх боловсролоос 12-р анги хүртэлх, Монголын анхны төсөлд суурилсан сургалттай амьдралд бэлтгэх цогцолбор сургууль
              </Text>
            </p>
            <div className="flex justify-center">
              <Link to='/apply' className="bg-white rounded-tl-xl py-2 px-4 rounded-br-xl font-bold">
                <Text>
                  ӨРГӨДӨЛ ИЛГЭЭХ
                </Text>
              </Link>
          </div>
        </div>
      
      </div>
 
    </div>
  )
}

export default CoverPage
