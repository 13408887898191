import { Text } from 'components'
import React from 'react'
import { AiFillInstagram } from 'react-icons/ai'
import { BsYoutube } from 'react-icons/bs'
import { FaFacebookSquare } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Contact() {
  return (
    <div className='px-5 md:px-14'>
      <div>
        <div className='font-bold text-4xl mb-8'><Text>Холбоо барих—</Text></div>
        <form className='flex flex-col gap-6'>
          <div className='flex gap-10'>
            <div className='flex flex-col w-1/2'>
              <label>First Name *</label>
              <input name='firstname' className='p-3 border bg-[#fafafa]'/>
            </div>
            <div className='flex flex-col w-1/2'>
              <label>Last Name *</label>
              <input name='firstname' className='p-3 border bg-[#fafafa]'/>
            </div>
          </div>
          <div className='flex flex-col'>
            <label>Email *</label>
            <input type={'email'} name='firstname' className='p-3 border bg-[#fafafa]'/>
          </div>
          <div className='flex flex-col'>
            <label>Message *</label>
            <textarea name='message' className='p-3 focus:ouline border bg-[#fafafa]'/>
          </div>
        </form>
      </div>
      <div className='flex flex-col sm:flex-row mt-5'>
        <div className='flex flex-col gap-2 w-full sm:w-1/2 mb-5'>
          <div className='flex items-center gap-1'>
            <ion-icon name="call" style={{fontSize: '22px'}}  ></ion-icon>
            <Text>Утасны дугаар</Text>: 72777311
          </div>
          <div className='flex items-center gap-1'>
            <ion-icon name="location" style={{fontSize: '22px'}}></ion-icon>
            <Text>Хаяг</Text>: <Text>Хан-Уул дүүрэг, 11-р хороо, Ар Зайсангийн ам, 17025, Улаанбаатар</Text>
          </div>
          <div className='flex items-center gap-1'>
            <ion-icon name="mail" style={{fontSize: '22px'}}></ion-icon>
            <Text>И-Мэйл хаяг</Text>: info@human.edu.mn
          </div>
          <div className='flex items-center gap-1'>
            <ion-icon name="time" style={{fontSize: '22px'}}></ion-icon>
            <Text>Ажлын цаг</Text>: 08:00 - 17:00
          </div>
          <div className='flex gap-2 sm:gap-5 flex-wrap'>
            <a className="flex items-center gap-2" href="https://youtube.com" target="_blank" rel="noreferrer">
              <BsYoutube className="text-xl"></BsYoutube> <span>Human International school</span>
            </a>
            <a className="flex items-center gap-2" href="https://instagram.com" target="_blank" rel="noreferrer">
              <AiFillInstagram className="text-xl"></AiFillInstagram> <span>humanintlschool</span>
            </a>
            <a className="flex items-center gap-2" href="https://facebook.com" target="_blank" rel="noreferrer">
              <FaFacebookSquare className="text-xl"></FaFacebookSquare> <span>Хүмүүн олон улсын сургууль</span>
            </a>
          </div>
        </div>
      </div>
      <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7570.18249712038!2d106.90816308395497!3d47.86934910535823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf97d64bde7074031!2z0KXSr9C80q_Sr9C9INC-0LvQvtC9INGD0LvRgdGL0L0g0YHRg9GA0LPRg9GD0LvRjA!5e0!3m2!1sen!2smn!4v1667903386819!5m2!1sen!2smn" width="100%" height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
      <div className='py-6 sm:py-14 flex justify-between text-3xl sm:text-4xl font-bold'>
        <Link to={'/community'} className='flex items-center gap-3 sm:gap-5'><ion-icon name="chevron-back-outline"></ion-icon> <Text>Хүчирхэг хүрээлэл</Text></Link>
      </div>
    </div>
  )
}

export default Contact